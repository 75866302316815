.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.nav-container {
  background-color: #f5f5f5;
}

.nav {
  display: flex;
  margin: 0 auto;
  padding: 5px 30px;
  width: 300px;
  align-items: center;
  justify-content: center;

  a {
    display: block;
    margin-right: 20px;
    font-weight: 500;
    padding: 5px;
    color: #333;
    text-decoration: none;
  }

  a.active {
    background-color: #c7dbe0;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
